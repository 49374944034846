<template>
  <v-overlay :color="type.color" :value="value" opacity="1" z-index="9">
    <v-btn @click="$emit('close')" class="rv-btn" large icon>
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="rv-valid">
      <!-- category -->
      <h1 v-if="result.level > 1" class="text-uppercase mb-7">
        {{ result.category }}
        <span v-if="result.category_benefit"
          >| {{ result.category_benefit }}</span
        >
      </h1>

      <!-- icon -->
      <v-icon class="text-h1 | mb-8">{{ type.icon }}</v-icon>
      <!-- title -->
      <h1 class="text-h5 | font-weight-bold">{{ type.msg }}</h1>
      <h1 v-if="result.name" class="text-h5 mt-3 | font-weight-medium">
        {{ result.name }}
      </h1>
      <p v-if="result.rut" class="font-weight-medium text-h5">
        {{ result.rut }}
      </p>

      <h4 class="text-h6" v-if="result.list">Lista: {{ this.result.list }}</h4>
      <h4 class="text-h6 | mb-6" v-if="result.list">
        Lista Hora: {{ formatDate(result.deadLine) }}
      </h4>
      <h4 class="text-h6" v-if="result.sellerName">
        Responsable: {{ this.result.sellerName }}
      </h4>

      <!-- actions -->
      <div v-if="result.level > 1" class="rv-valid--actions">
        <v-btn @click="$emit('close')" block large> Aceptar </v-btn>
      </div>
      <div v-else class="rv-valid--actions">
        <v-btn v-if="!result.enable" @click="$emit('close')" block large>
          Cerrar
        </v-btn>
        <v-btn
          v-if="result.enable"
          :disabled="loading"
          @click="onSubmit(false)"
          block
          large
        >
          Rechazar
        </v-btn>
        <v-btn
          color="blue darken-4"
          class="mt-7"
          :disabled="loading"
          v-if="result.enable"
          block
          large
          @click="onSubmit(true)"
        >
          Aceptar
        </v-btn>
      </div>
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="indigo"
      class="mt-6"
    />
  </v-overlay>
</template>

<script>
import _ from "lodash";
moment.locale("es");
import moment from "moment";
import { mapActions } from "vuex";

export default {
  props: ["value", "result"],

  data: () => ({
    type: null,
    loading: false,
  }),

  methods: {
    ...mapActions("attendees", ["scanUpdateTicket"]),

    formatDate(value) {
      if (!value) return; // ? Fixed
      var date = value.toDate();
      date = moment(date).format("HH:mm");
      return date;
    },

    setType(x) {
      if (x.enable) {
        if (x.level > 1) {
          this.type = {
            color: "amber",
            msg: "Asistente Habilitado",
            icon: "mdi-star",
          };

          return;
        }

        this.type = {
          color: "success",
          msg: "Asistente Habilitado",
          icon: "mdi-check",
        };
      } else {
        this.type = { color: "error", msg: x.motive, icon: "mdi-close" };
      }
    },

    async onSubmit(entered) {
      this.loading = true;
      await this.scanUpdateTicket(
        _.merge({ entered: entered }, this.result, {
          event_id: this.$route.params.uid,
        })
      );
      this.loading = false;
      this.$emit("close", entered);
    },
  },

  created() {
    this.setType(this.result);
  },
};
</script>

<style lang="scss" scoped>
.rv-valid {
  width: 300px;
  display: flex;
  text-align: center;
  flex-direction: column;

  &--actions {
    margin-top: 30px;
  }
}

.rv-btn {
  top: 10px;
  right: 10px;
  position: fixed;
}
</style>