<template>
  <v-app-bar app flat :color="getTheme" v-if="isMobile">
    <!-- button: menu -->
    <v-app-bar-nav-icon @click="$root.$emit('drawer')" v-if="!level"/>

    <!-- button: back -->
    <v-btn v-else icon :to="{ name: 'MyEvents' }">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <!-- title -->
    <v-toolbar-title v-text="title" class="font-weight-semibold pl-2"/>
    <v-spacer/>

    <!-- button: action -->
    <v-btn @click="$emit('input', true)" v-if="btn && allowed" icon :disabled="disabled">
      <v-icon>mdi-plus-circle-outline</v-icon>
    </v-btn>

    <!-- Loading -->
    <v-progress-linear :active="loading" indeterminate absolute bottom/>
  </v-app-bar>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: ["title", "level", "btn", "loading"],

  data: () => ({}),

  computed: {
    ...mapState("config", ["disabled"]),


    getTheme() {
      return this.$vuetify.theme.dark ? null : "white";
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    allowed() {
      if (this.$store.state.auth.user.role === "owner") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>