// TODO: Delete this file
// Headers
const tableEventsO = [
    { text: "EVENTO", value: "name" },
    { text: "FECHA", value: "dateStart" },
    { text: "HORA INICIO", value: "timeStart" },
    { text: "HORA FIN", value: "timeEnd" },
    { text: "ACTIVO", value: "active", width: "10%" },
]
const tableEventsS = [
    { text: "EVENTO", value: "name" },
    { text: "FECHA", value: "dateStart" },
    { text: "HORA INICIO", value: "timeStart" },
    { text: "HORA FIN", value: "timeEnd" },
]

const tableAttendeesO = [
    { text: "RUT", value: "rut" },
    { text: "NOMBRE", value: "name" },
    { text: "LISTA", value: "list" },
    { text: "RESPONSABLE", value: "sellerName" },
    { text: "ESTADO TICKET", value: "activeTicket" },
    { text: "", value: "actions" },
]

const tableAttendeesScan = [
    { text: "Asistente", value: "name" },
    { text: "Rut", value: "rut" },
]







// 00. Owner: My Users
const headerMyUsers = [
    { text: "Nombre", value: "displayName" },
    { text: "Email", value: "email" },
    { text: "Estado", value: "disabled" },
    { text: "Eliminar", value: "actions" },
]






// 00. Rules
const rulesForm = [
    {
        required: (value) => !!value || "Obligatorio.",
        count: (v) => (v && v.length >= 9) || "Ingrese un RUT válido",
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "E-mail no válido.";
        },
    },
]


export { tableEventsO, tableEventsS, tableAttendeesO, tableAttendeesScan, rulesForm, headerMyUsers }