<template>
  <v-container class="black fill-height | pa-0" style="position: relative">
    <div class="element"></div>
    <qrcode-stream :track="paintOutline" :camera="camera" @decode="onDecode" />

    <v-dialog v-model="loading"  persistent width="300">
      <v-card dark>
        <v-card-text class="pt-3">
          Buscando Asistente
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions } from "vuex";

export default {
  components: { QrcodeStream },

  data: () => ({
    camera: "auto",
    loading: false,
  }),

  methods: {
    ...mapActions("attendees", ["scanFindAttendee"]),

    async onDecode(capture) {
      this.loading = true;
      this.camera = "off";

      const event_id = this.$route.params.uid;
      const attendee_id = new URL(capture).searchParams.get("RUN");

      await this.scanFindAttendee({ id: attendee_id, event: event_id }).then(
        (resp) => {
          this.$emit("findAttendee", resp);
        }
      );

      this.camera = "auto";
      this.loading = false;
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$top: 42%;
$left: 34.8%;
$right: 66%;
$bottom: 58.5%;

.element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(black, 0.6);

  clip-path: polygon(
    0% 0%,
    0% 100%,
    $left 100%,
    $left $top,
    $right $top,
    $right $bottom,
    25% $bottom,
    25% 100%,
    100% 100%,
    100% 0%
  );
}
</style>